
.prod-landing-polaris {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	@media only screen and (min-width : 1024px) {
		background-image: url(/assets/img/polaris-landing_sm.jpg);
	}

	/* Desktops and laptops ----------- */
	@media only screen and (min-width : 1224px) {
		background-image: url(/assets/img/polaris-landing.jpg);
	}

	/* Large screens ----------- */
	@media only screen and (min-width : 1824px) {
		background-image: url(/assets/img/polaris-landing_lg.jpg);
	}
	.login-wrapper {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center); 
		width: 100vw;
		height:100vh;
		.login-card {
			width: 550px;
			text-align: center;
			.login-top {
				padding: 1.667rem 0;
				border-radius: 5px 5px 0 0;
				background-color: transparentize(black, .1);
				h1 {
					font-size: 2rem;
					color: $polaris-blue;
					font-weight: bold;
				}
				h3 {
					font-size: 1.4rem;
					color: $almost-white;
					font-weight: $semibold;
					span { 
						font-size: 1rem;
						font-weight: $light;
						padding-right: 3px;
					}
				}
			}
			.login-bottom {
				margin-top: 2px;
				padding: 2rem 0;
				background-color: transparentize(black, .1);
				border-radius: 0 0 5px 5px;
				a { 
					color: white; 
					cursor: pointer;
					text-decoration: underline;
				}
				p {
					color: $almost-white;
					font-weight: $light;
					margin: 0;
					line-height: 3rem;
				}
				.sign-up-code {
					@include flexbox;
					@include justify-content(center);
					@include align-items(center);
					white-space: nowrap;
					.input-container {
						@include flexbox;
						@include align-items(center);
						overflow: hidden;
						max-width: 0;
						transition: none;	
						label {
							font-size: .8667rem;
							color: $off-white;
							margin-right: 1rem;
						}
						input {
							height: 28px;
							width: 80px;
							margin-bottom: 0;
							padding: 0 .333rem;
							text-align: center;
							color: white;
							font-weight: $semibold;
							&:focus {
								background: transparentize(#fafafa, .2);
								color: $dark_gray;
							}
						}
						button {
							background: transparent;
							text-transform: none;
							height: unset;
							padding: 0;
							font-style: italic;
							font-weight: normal;
							min-width: unset;
							margin-left: 1rem;
							text-decoration: underline;
							margin-right: 50px;
						}
					}			
				}
			}
		}
		.branding {
			height: 50px;
			background-image: url(/assets/img/polaris-logo_white.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin-top: 5rem;
		}
	}
}


